/**
 * Nav
 */

 
import * as focusTrap from 'focus-trap';

export default function() {

	let burger = document.querySelector('.c-header-burger')
	let header = document.querySelector('.c-header')
	let headerLogo = document.querySelector('.c-header-logo a')

	let labels = {
		open:{
			en: "open the menu",
		},
		close:{
			en: "close the menu",
		},
	}

	let getMenuState = function() {
		let menuState = document.body.getAttribute('data-menu-open')
		return(menuState)
	}

	let menuDefault = function() {
		document.body.setAttribute('data-menu-open',false)
		burger.setAttribute('aria-label',labels.open.en)
	}

	let menuOpened = function() {
		document.body.setAttribute('data-menu-open',true)
		focusTrap.createFocusTrap (header,{clickOutsideDeactivates: true,}).activate()
		burger.setAttribute('aria-label',labels.close.en)
		headerLogo.setAttribute('aria-hidden',true)
		headerLogo.setAttribute('tabindex','-1')
	}

	let menuClosed = function() {
		document.body.setAttribute('data-menu-open',false)
		focusTrap.createFocusTrap (header,{clickOutsideDeactivates: true,}).deactivate()
		burger.setAttribute('aria-label',labels.open.en)
		headerLogo.removeAttribute('aria-hidden')
		headerLogo.removeAttribute('tabindex')
	}

	menuDefault()

	burger.onclick = function() {
		getMenuState() == "false" ? menuOpened() : menuClosed()
	}

	burger.addEventListener('keyup', (event) => {
		if(event.keyCode === 13 || event.keyCode === 32) {
			getMenuState() == "false" ? menuOpened() : menuClosed()
		}
	});

}
