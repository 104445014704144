/**
 * Lazy
 */

import LazyLoad from "vanilla-lazyload";

export default function() {


	window.lazyFunctions = {
		parentLoaded: function (element) {
			element.parentElement.classList.add("loaded");
		},
	};

	function executeLazyFunction(element) {
		var lazyFunctionName = element.getAttribute("data-lazy-function");
		var lazyFunction = window.lazyFunctions[lazyFunctionName];
		if (!lazyFunction) return;
		lazyFunction(element);
	}
	
	var myLazyLoad = new LazyLoad({
		unobserve_entered: true, 
		callback_enter: executeLazyFunction
	});

	myLazyLoad.update();

}