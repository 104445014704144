/**
 * Cookie consent
 */

import * as focusTrap from 'focus-trap';

export default function (props) {
	const self = this
	self.props = {
			buttonPrimaryClass: "o-link --highlight", 
			buttonSecondaryClass: "o-link --highlight",
			autoShowModal: true,
			blockAccess: false,
			lang: navigator.language,
			defaultLang: "en",
			content: { 
					en: {
							title: "🍪 Cookie settings",
							body: "I use cookies to personalize content and analyze access to my website. ",
							privacyPolicy: "privacy policy",
							buttonAcceptAll: "Accept all cookies",
							buttonAcceptTechnical: "Dismiss"
					}
			},
			cookieName: "cookie-consent-tracking-allowed",
			modalId: "cookieConsentModal"
	}
	for (let property in props) {
			if(property !== "content") {
					// noinspection JSUnfilteredForInLoop
					self.props[property] = props[property]
			}
	}
	for (let contentProperty in props.content) {
			// noinspection JSUnfilteredForInLoop
			self.props.content[contentProperty] = props.content[contentProperty]
	}
	self.lang = self.props.lang
	if (self.lang.indexOf("-") !== -1) {
			self.lang = self.lang.split("-")[0]
	}
	if (self.props.content[self.lang] === undefined) {
			self.lang = self.props.defaultLang
	}
	const _t = self.props.content[self.lang]
	const linkPrivacyPolicy = '<a href="' + self.props.privacyPolicyUrl + '">' + _t.privacyPolicy + '</a>'
	self.modalContent = '<!-- cookie banner -->' +
			'<div class="c-cookie" mode="dark">' +
			'<p class="h5">--header--</p>' +
			'<p>--body--</p><div class="o-spacer-1"></div>' +
			'<div class="modal-footer">--footer--</div>' +
			'</div><!-- end cookie-consent.js -->'
	self.modalContent = self.modalContent.replace(/--header--/, "<h3 class=\"modal-title\">" + _t.title + "</h3>")
	self.modalContent = self.modalContent.replace(/--body--/,
			_t.body.replace(/--privacy-policy--/, linkPrivacyPolicy)
	)
	self.modalContent = self.modalContent.replace(/--footer--/,
			"<div class='buttons'>" +
			"<button class='btn-accept-necessary " + self.props.buttonSecondaryClass + "'>" + _t.buttonAcceptTechnical + "</button>" +
			"<button class='btn-accept-all " + self.props.buttonPrimaryClass + "'>" + _t.buttonAcceptAll + "</button>" +
			"</div>"
	)

	function setCookie(name, value, days) {
			let expires = ""
			if (days) {
					const date = new Date()
					date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
					expires = "; expires=" + date.toUTCString()
			}
			document.cookie = name + "=" + (value || "") + expires + "; Path=/; SameSite=Strict;"
	}

	function getCookie(name) {
			const nameEQ = name + "="
			const ca = document.cookie.split(';')
			for (let i = 0; i < ca.length; i++) {
					let c = ca[i]
					while (c.charAt(0) === ' ') {
							c = c.substring(1, c.length)
					}
					if (c.indexOf(nameEQ) === 0) {
							return c.substring(nameEQ.length, c.length)
					}
			}
			return undefined
	}

	function removeCookie(name) {
			document.cookie = name + '=; Path=/; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
	}

	function documentReady(fn) {
			if (document.readyState !== 'loading') {
					fn()
			} else {
					document.addEventListener('DOMContentLoaded', fn)
			}
	}

	function hideDialog() {
			self.modal.style.display = "none"
			focusTrap.createFocusTrap (self.modal,{clickOutsideDeactivates: true,}).deactivate()
	}

	function showDialog() {
			documentReady(function () {
					self.modal = document.getElementById(self.props.modalId)
					if (!self.modal) {
							self.modal = document.createElement("div")
							self.modal.id = self.props.modalId
							self.modal.innerHTML = self.modalContent
							document.body.prepend(self.modal)
							self.modal.querySelector(".btn-accept-necessary").addEventListener("click", function () {
									setCookie(self.props.cookieName, "false", 365)
									hideDialog()
									if(self.props.postSelectionCallback) {
											self.props.postSelectionCallback()
									}
							})
							self.modal.querySelector(".btn-accept-all").addEventListener("click", function () {
									setCookie(self.props.cookieName, "true", 365)
									hideDialog()
									if(self.props.postSelectionCallback) {
											self.props.postSelectionCallback()
									}
							})
					} else {
							self.modal.style.display = "block"
					}
					focusTrap.createFocusTrap (self.modal,{clickOutsideDeactivates: true,}).activate()
			}.bind(this))
	}

	if (getCookie(self.props.cookieName) === undefined && self.props.autoShowModal) {
			showDialog()
	}

	// API
	self.reset = function () {
			removeCookie(self.props.cookieName)
			showDialog()
	}

	self.trackingAllowed = function () {
			return getCookie(self.props.cookieName) === "true"
	}
}

