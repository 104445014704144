/**
 * Tab
 */


export default function() {

	// remove tab state for click
  document.body.addEventListener('mousedown', function() {
    document.body.classList.add('--noTab');
  });

  // reset tab state
  document.querySelector('body').addEventListener('keydown', function(event) {
    var code = event.keyCode || event.which;
    if (code === 9) {  
      document.body.classList.remove('--noTab');
    }
  });

}