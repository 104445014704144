/**
 * App
 */

// Utils
import lazy from './utils/utils.lazy';
//import mode from './utils/utils.mode';
import tab from './utils/utils.tab';
import click from './utils/utils.click';

// Components
import anim from './components/components.anim';
import nav from './components/components.nav';
import cookieConsent from './components/components.cookieConsent';
window.cookieConsent = cookieConsent;

document.addEventListener('DOMContentLoaded', function() {

  // Utils
  lazy();
  //mode();
  tab();
  click();
  
  // Components
  anim();
  nav();

});