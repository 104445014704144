/**
 * Click
 */


export default function() {

	let clickable = document.querySelectorAll('.is-clickable')
  for (const click of clickable) {
    click.addEventListener('click', function() {
      this.querySelector('a').click();
    })
  }

}